<template>
  <div>
    <b-overlay :show="loading">
      <table class="table table-sm">
        <thead>
          <tr>
            <th width="200px">Timestamp</th>
            <th width="250px">Remote</th>
            <th width="170px">User</th>
            <th>Event</th>
          </tr>
        </thead>
        <tbody v-if="log">
          <tr v-for="(e,i) in log" :key="`LE_${i}`">
            <td>{{e.timestamp}}</td>
            <td>{{e.remote_host}}</td>
            <td>{{e.user}}</td>
            <td>{{e.message}}</td>
          </tr>
        </tbody>
      </table>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data: () => ({
    log: null,
    loading: false
  }),
  methods: {
    async reload () {
      this.loading = true
      try {
        this.log = await API.logs.get_system()
      } catch (error) {
        alert(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
